function App() {
  return (
    <div className='main'>
      <h1>不承认俄罗斯的任何假投票！！！</h1>
      <p>
        1945年10月，國民政府「參觀」外蒙公民投票之代表[3]雷法章的報告書指出，外蒙當局當時已受到蘇聯在軍事、政治、經濟上所控制，在軍政文化機關，多有蘇聯人員在內工作，且在投票所、教育機關也可見到蘇聯方面高級人員參與。且在經濟方面，多仰賴蘇聯方面給予資助，故其資源物資來源以受蘇聯遙控。而在投票所的公民投票，雖稱爲外蒙人民重向世界表示獨立行動，實則在政府人員監督之下，以公開之簽字方式表示贊成與否，人民實難表示自由之意志。[4]
        雷法章則在事後的報告中稱：「我抵達庫倫....與外蒙古國務總理兼外交部長兼大元帥喬巴山晤談多次....喬巴山曾經在正式投票前很自信的對我說：『至少有百分之九十以上的公民投贊成票』。結果不出所料，足見他們組織嚴密，控制徹底了。」
      </p>
    </div>
  );
}

export default App;
